import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DirectUpload } from '@rails/activestorage';
import classnames from 'classnames';
import debounce from 'lodash.debounce';
import Promise from 'bluebird';
import uploadStates from '../uploadStates';
import ProgressBar from './ProgressBar';
import Spinner from './Spinner';
import { localPostJSON } from '../localFetch';
import uniqueId from '../uniqueId';
import humanSize from '../humanSize';
import TagSelect from './TagSelect';
import checksumForFile from '../checksumForFile';
import readFileData from '../readFileData';

class Uploader {
  constructor(onUpdateProgress) {
    this.onUpdateProgress = onUpdateProgress;
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener(
      'progress',
      debounce(event => this.onUpdateProgress(event), 20),
    );
  }
}

// https://stackoverflow.com/a/25095250/456337
const containsFiles = event => {
  if (event.dataTransfer.types) {
    for (let i = 0; i < event.dataTransfer.types.length; i += 1) {
      if (event.dataTransfer.types[i] === 'Files') {
        return true;
      }
    }
  }

  return false;
};

// https://github.com/react-dropzone/react-dropzone
export const getDataTransferItems = event => {
  let dataTransferItemsList = [];
  if (event.dataTransfer) {
    const dt = event.dataTransfer;
    if (dt.files && dt.files.length) {
      dataTransferItemsList = dt.files;
    } else if (dt.items && dt.items.length) {
      // During the drag even the dataTransfer.files is null but Chrome
      // implements some drag store, which is accesible via dataTransfer.items
      dataTransferItemsList = dt.items;
    }
  } else if (event.target && event.target.files) {
    dataTransferItemsList = event.target.files;
  }
  // Convert from DataTransferItemsList to the native Array
  return Array.prototype.slice.call(dataTransferItemsList);
};

const onDocumentDragOver = event => event.preventDefault();
const handleDragOver = event => event.preventDefault();

const initialState = {
  images: [],
  uploadsWithErrors: [],
  selectedFeeds: [],
  selectedTags: [],
  draggedOver: false,
};

export default class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    document.addEventListener('dragover', onDocumentDragOver, false);
    document.addEventListener('drop', this.onDocumentDrop, false);
  }

  componentWillUnmount() {
    document.removeEventListener('dragover', onDocumentDragOver);
    document.removeEventListener('drop', this.onDocumentDrop);
  }

  onDocumentDrop = event => {
    if (this.draggableNode === event.target) return;
    event.preventDefault();
  };

  onRemoveImage = imageId => {
    this.setState(state => ({
      images: state.images.filter(({ id }) => id !== imageId),
    }));
  };

  onRemoveImages = () => {
    this.setState(() => initialState);
  };

  onSubmit = () => {
    const { images } = this.state;

    images.forEach(image => {
      this.updateImage(image, img => ({
        ...img,
        uploadState: {
          type: uploadStates.UPLOAD_LOADING,
          data: null,
        },
      }));
    });

    localPostJSON('/image_batch', this.uploadPayload())
      .then(response => {
        images.forEach((image, index) => {
          const datum = response[index];
          const type = datum.id
            ? uploadStates.UPLOAD_SUCCESS
            : uploadStates.UPLOAD_FAILURE;
          const data = datum.id ? datum : datum.errors;

          this.updateImage(image, img => ({
            ...img,
            uploadState: { type, data },
          }));
        });

        this.setState(() => ({ selectedTags: [], selectedFeeds: [] }));
      })
      .catch(error => {
        images.forEach(image => {
          this.updateImage(image, img => ({
            ...img,
            uploadState: {
              type: uploadStates.UPLOAD_FAILURE,
              data: [error],
            },
          }));
        });
      });
  };

  onSelectFiles = event => {
    const files = Array.from(event.target.files);
    this.addImageFiles(files);
  };

  onUpload = () => {
    const { images } = this.state;
    const { directUploadUrl } = this.props;

    const imagesToDirectUpload = images.filter(
      image =>
        image.directUploadState.type === uploadStates.DIRECT_UPLOAD_NOT_ASKED,
    );

    imagesToDirectUpload.forEach(image => {
      const delegate = new Uploader(
        this.directUploadDidProgress.bind(this, image),
      );
      const upload = new DirectUpload(image.file, directUploadUrl, delegate);

      this.updateImage(image, img => ({
        ...img,
        directUploadState: {
          type: uploadStates.DIRECT_UPLOAD_IN_PROGRESS,
          data: 0,
        },
      }));

      upload.create((error, blob) => {
        if (error) {
          this.setState(prevState => ({
            images: prevState.images.filter(({ id }) => id !== image.id),
            uploadsWithErrors: [
              ...prevState.uploadsWithErrors,
              {
                id: uniqueId('direct-upload-error'),
                errorMessages: ['There was an issue uploading this file'],
                file: image.file,
              },
            ],
          }));
        } else {
          this.updateImage(image, img => ({
            ...img,
            directUploadState: {
              type: uploadStates.DIRECT_UPLOAD_SUCCESS,
              data: blob.signed_id,
            },
          }));
        }
      });
    });
  };

  addImageFiles = files => {
    const { state } = this;

    const checksumPromises = files.map(checksumForFile);

    const imageData = Promise.filter(
      checksumPromises,
      obj =>
        state.images.map(img => img.checksum).filter(cs => cs === obj.checksum)
          .length === 0,
    ).map(({ file, checksum }) =>
      readFileData(file).then(data => ({
        id: uniqueId('image'),
        file,
        fileData: data,
        checksum,
        directUploadState: {
          type: uploadStates.DIRECT_UPLOAD_NOT_ASKED,
          data: null,
        },
        uploadState: {
          type: uploadStates.UPLOAD_NOT_ASKED,
          data: null,
        },
      })),
    );

    Promise.all(imageData).then(images => {
      this.setState(prevState => {
        const newState = this.uploadComplete()
          ? {
              images,
              uploadsWithErrors: [],
            }
          : {
              images: [...prevState.images, ...images],
              uploadsWithErrors: [],
            };
        return newState;
      }, this.onUpload);
    });
  };

  uploadPayload = () => {
    const { canTag, canPublish } = this.props;
    const { images, selectedTags, selectedFeeds } = this.state;

    const blobs = images
      .filter(
        ({ directUploadState }) =>
          directUploadState.type === uploadStates.DIRECT_UPLOAD_SUCCESS,
      )
      .map(({ directUploadState }) => directUploadState.data);

    let additionalPayload = {};

    if (canTag) {
      additionalPayload = {
        ...additionalPayload,
        tag_ids: selectedTags.map(({ value }) => parseInt(value, 10)),
      };
    }

    if (canPublish) {
      additionalPayload = {
        ...additionalPayload,
        feed_ids: selectedFeeds,
      };
    }

    const payload = {
      images: {
        ...additionalPayload,
        images: blobs,
      },
    };

    return payload;
  };

  validateFile = file => {
    const { type, size } = file;

    const { acceptedMIMETypes, maxSize } = this.props;

    const fileValidations = [
      {
        fn: () => size <= maxSize,
        message: `This images is too big! Please choose a file under ${humanSize(
          maxSize,
        )}`,
      },
      {
        fn: () => acceptedMIMETypes.indexOf(type) >= 0,
        message: 'This file is not an image!',
      },
    ];

    const errorMessages = fileValidations.reduce(
      (r, validator) => [...r, ...(validator.fn() ? [] : [validator.message])],
      [],
    );

    return {
      id: uniqueId('upload-error'),
      errorMessages,
      file,
    };
  };

  handleDrop = event => {
    event.preventDefault();
    event.stopPropagation();

    const rawFileList = getDataTransferItems(event);
    const fileList = Array.from(rawFileList);
    const files = fileList.filter(
      file => this.validateFile(file).errorMessages.length === 0,
    );
    const errors = fileList
      .map(this.validateFile)
      .filter(l => l.errorMessages.length > 0);

    if (containsFiles(event) && files.length > 0) {
      this.addImageFiles(files);
    }

    if (containsFiles(event) && errors.length > 0) {
      this.setState(() => ({ uploadsWithErrors: errors }));
    }

    this.setState(() => ({ draggedOver: false }));
  };

  handleDragLeave = event => {
    event.preventDefault();
    this.setState(() => ({ draggedOver: false }));
  };

  handleDragEnter = event => {
    event.preventDefault();
    this.setState(() => ({ draggedOver: true }));
  };

  selectFeed = event => {
    const { checked, value } = event.target;
    const targetId = parseInt(value, 10);
    if (checked) {
      this.setState(state => ({
        selectedFeeds: [...state.selectedFeeds, targetId],
      }));
    } else {
      this.setState(state => ({
        selectedFeeds: state.selectedFeeds.filter(id => id !== targetId),
      }));
    }
  };

  selectTags = tags => {
    this.setState(() => ({ selectedTags: tags }));
  };

  tagCreated = tag => {
    this.setState(({ selectedTags }) => ({
      selectedTags: [...selectedTags, tag],
    }));
  };

  updateImage = (image, f) => {
    this.setState(prevState => ({
      images: prevState.images.map(img => (img.id === image.id ? f(img) : img)),
    }));
  };

  directUploadDidProgress = (image, event) => {
    this.updateImage(image, img => {
      if (
        img.directUploadState.type === uploadStates.DIRECT_UPLOAD_SUCCESS ||
        img.directUploadState.type === uploadStates.DIRECT_UPLOAD_FAILURE
      )
        return img;

      const progress = event.loaded / event.total;

      return {
        ...img,
        directUploadState: {
          type: uploadStates.DIRECT_UPLOAD_IN_PROGRESS,
          data: progress,
        },
      };
    });
  };

  successfullyUploadedImages = () => {
    const { images } = this.state;

    return images.filter(
      ({ uploadState }) => uploadState.type === uploadStates.UPLOAD_SUCCESS,
    );
  };

  uploadFailures = () => {
    const { images } = this.state;

    return images.filter(
      ({ uploadState }) => uploadState.type === uploadStates.UPLOAD_FAILURE,
    );
  };

  uploadComplete = () =>
    this.successfullyUploadedImages().length > 0 ||
    this.uploadFailures().length > 0;

  render() {
    const { acceptedMIMETypes, canTag, canPublish, feeds } = this.props;
    const {
      images,
      draggedOver,
      selectedFeeds,
      selectedTags,
      uploadsWithErrors,
    } = this.state;

    const successfullyUploadedImages = this.successfullyUploadedImages();
    const uploadFailures = this.uploadFailures();

    const numberOfSuccesses = successfullyUploadedImages.length;
    const numberOfErrors = uploadFailures.length;

    const uploadComplete = this.uploadComplete();

    const uploadingBatch =
      images.length > 0 &&
      images[0].uploadState.type === uploadStates.UPLOAD_LOADING;

    const uploadingSuccess = numberOfSuccesses > 0;
    const uploadingFailure = numberOfErrors > 0;

    const progressForImage = ({ directUploadState }) => {
      switch (directUploadState.type) {
        case uploadStates.DIRECT_UPLOAD_IN_PROGRESS:
          return directUploadState.data;

        case uploadStates.DIRECT_UPLOAD_SUCCESS:
          return 1;

        default:
          return 0;
      }
    };

    const imagePreviews = images.map(image => {
      const { file, id, directUploadState } = image;

      const progressBar = (
        <ProgressBar
          progress={progressForImage(image)}
          state={directUploadState.type}
        />
      );

      return (
        <div key={id} className="upload-item">
          <div className="upload-item__img">
            <img src={image.fileData} className="upload-item-preview" alt="" />
          </div>

          <div className="upload-item__body">
            <div className="upload-item__title">
              <strong>{file.name}</strong>
              <i style={{ marginLeft: '40px' }} className="muted">
                {humanSize(file.size)}
              </i>
              <button
                type="button"
                title="Remove this image"
                className="icon icon-close-small"
                onClick={() => this.onRemoveImage(id)}
              />
            </div>
            {progressBar}
          </div>
        </div>
      );
    });

    const errorPreviews = uploadsWithErrors.map(error => {
      const { id, file, errorMessages } = error;

      const errorsUI = uploadsWithErrors.length > 0 && (
        <ul>
          {errorMessages.map(message => (
            <li key={uniqueId('errorMessage')}>{message}</li>
          ))}
        </ul>
      );

      return (
        <div key={id}>
          <strong>{file.name}</strong>
          {errorsUI}
        </div>
      );
    });

    const feedUI = canPublish && (
      <React.Fragment>
        <hr />
        <h2>Feeds</h2>

        {feeds.map(({ id, label }) => (
          <label key={id} htmlFor={`feed-${id}`} className="checkbox">
            <input
              type="checkbox"
              checked={selectedFeeds.indexOf(id) >= 0}
              id={`feed-${id}`}
              value={id}
              onChange={this.selectFeed}
            />{' '}
            {label}
          </label>
        ))}
      </React.Fragment>
    );

    const tagUI = canTag && (
      <React.Fragment>
        <hr />
        <h2>Tags</h2>
        <TagSelect
          selection={selectedTags}
          onChange={this.selectTags}
          onCreate={this.tagCreated}
        />
      </React.Fragment>
    );

    const numberOfImages = images.length;
    const buttonLabel =
      numberOfImages === 0
        ? 'Upload'
        : `Upload ${numberOfImages} image${numberOfImages === 1 ? '' : 's'}`;

    const draggableProps = {
      onDrop: this.handleDrop,
      onDragOver: handleDragOver,
      onDragEnter: this.handleDragEnter,
      onDragLeave: this.handleDragLeave,
    };

    const uploadAreaclasses = classnames('upload-area', {
      'upload-area--dragged-over': draggedOver,
    });

    // FIXME: this needs to ensure that there are no pending direct uploads,
    // and if not, there are more than 1 successful non-error uploads
    const imageComplete = ({ directUploadState }) =>
      directUploadState.type === uploadStates.DIRECT_UPLOAD_SUCCESS ||
      directUploadState.type === uploadStates.DIRECT_UPLOAD_FAILURE;

    const imageSuccessful = ({ directUploadState }) =>
      directUploadState.type === uploadStates.DIRECT_UPLOAD_SUCCESS;

    const allImagesDirectUploadingComplete = images
      .map(imageComplete)
      .reduce((r, b) => r && b, true);

    const uploadDisabled =
      images.filter(imageSuccessful).length === 0 ||
      !allImagesDirectUploadingComplete ||
      uploadComplete;

    const showClearButton = images.length > 0;

    const successUI = uploadingSuccess && (
      <div className="notification notification--success">
        <p>
          Successfully uploaded {numberOfSuccesses} image
          {numberOfSuccesses === 1 ? '' : 's'}.
        </p>
      </div>
    );

    const uploadingUI = uploadingBatch && (
      <div className="notification notification--notice">
        <p>
          Uploading {images.length} image{images.length === 1 ? '' : 's'}.
        </p>
        <Spinner />
      </div>
    );

    const mainUI = !uploadingBatch && !uploadingSuccess && !uploadingFailure && (
      <React.Fragment>
        {imagePreviews}

        {uploadsWithErrors.length > 0 && (
          <div className="notification notification--alert">
            {errorPreviews}
          </div>
        )}
      </React.Fragment>
    );

    const uploadErrorsUI = uploadFailures.map(image => {
      const { id, file, uploadState } = image;

      return (
        <div key={id}>
          <strong>{file.name}</strong>
          <ul>
            {uploadState.data.map(message => (
              <li key={uniqueId('errorMessage')}>{message}</li>
            ))}
          </ul>
        </div>
      );
    });

    const uploadFailureUI = numberOfErrors > 0 && (
      <div className="notification notification--alert">
        <p>
          Failed to upload {numberOfErrors} image
          {numberOfErrors === 1 ? '' : 's'}:
        </p>
        {uploadErrorsUI}
      </div>
    );

    return (
      <div>
        <hr />

        <div className="uploader">
          <div className="uploader__panel">
            <label
              htmlFor="file"
              className={uploadAreaclasses}
              {...draggableProps}
            >
              <span style={{ pointerEvents: 'none' }}>
                Drag files to upload{images.length > 0 ? ' more' : ''}
              </span>

              <input
                type="file"
                id="file"
                multiple
                accept={acceptedMIMETypes.join(', ')}
                onChange={this.onSelectFiles}
              />
            </label>

            <div className="form-vertical">{feedUI}</div>

            {tagUI}

            {!uploadComplete && (
              <React.Fragment>
                <hr />

                <button
                  className="button"
                  type="button"
                  onClick={this.onSubmit}
                  disabled={uploadDisabled}
                >
                  {buttonLabel}
                </button>

                {showClearButton && (
                  <button
                    type="button"
                    title="Remove all files"
                    className="button-pseudo button--small"
                    onClick={this.onRemoveImages}
                  >
                    Clear uploads
                  </button>
                )}
              </React.Fragment>
            )}
          </div>

          <div className="uploader__main">
            <h3 className="h2">Uploading</h3>
            {successUI}
            {uploadingUI}
            {mainUI}
            {uploadFailureUI}
          </div>
        </div>
      </div>
    );
  }
}

ImageUploader.propTypes = {
  maxSize: PropTypes.number.isRequired,
  acceptedMIMETypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  directUploadUrl: PropTypes.string.isRequired,
  feeds: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, label: PropTypes.string }),
  ).isRequired,
  canPublish: PropTypes.bool.isRequired,
  canTag: PropTypes.bool.isRequired,
};
