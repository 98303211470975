export default {
  DIRECT_UPLOAD_NOT_ASKED: 'direct-upload-not-asked',
  DIRECT_UPLOAD_IN_PROGRESS: 'direct-upload-in-progress',
  DIRECT_UPLOAD_FAILURE: 'direct-upload-failure',
  DIRECT_UPLOAD_SUCCESS: 'direct-upload-success',

  UPLOAD_NOT_ASKED: 'upload-not-asked',
  UPLOAD_LOADING: 'upload-loading',
  UPLOAD_FAILURE: 'upload-failure',
  UPLOAD_SUCCESS: 'upload-success',
};
