/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import '@babel/polyfill';
import Rails from 'rails-ujs';
import * as ActiveStorage from '@rails/activestorage';

// Import images
const importAll = r => r.keys().map(r);
importAll(require.context('../images', true, /\.(gif|png|jpe?g|svg)$/));

const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

Rails.start();
ActiveStorage.start();

ReactRailsUJS.useContext(componentRequireContext);

const initBasementNav = () => {
  const selector = ['.js-menu-trigger', '.js-menu-screen'].join(',');

  const mobileSelector = ['.js-menu', '.js-menu-screen'].join(',');

  const clickables = document.querySelectorAll(selector);
  const mobileElements = document.querySelectorAll(mobileSelector);

  for (let i = 0; i < clickables.length; i += 1) {
    clickables[i].addEventListener('click', () => {
      for (let j = 0; j < mobileElements.length; j += 1) {
        mobileElements[j].classList.toggle('is-visible');
      }
    });
  }
};

const initDropDown = () => {
  const navTriggers = document.querySelectorAll('.js-nav-trigger');

  for (let i = 0; i < navTriggers.length; i += 1) {
    const trigger = navTriggers[i];
    trigger.addEventListener('click', function dropDownTrigger(e) {
      e.preventDefault();
      this.classList.toggle('active');
      const triggerParent = this.parentNode;
      const subNav = triggerParent.querySelector('.sub-nav');
      subNav.classList.toggle('active');
    });
  }
};

const initFileUpload = () => {
  const inputs = document.querySelectorAll('.input-file');

  Array.prototype.forEach.call(inputs, input => {
    const label = input.nextElementSibling;
    const labelVal = label.innerHTML;

    input.addEventListener('change', function(e) {
      let fileName = '';
      if (this.files && this.files.length > 1) {
        fileName = (this.getAttribute('data-multiple-caption') || '').replace(
          '{count}',
          this.files.length,
        );
      } else fileName = e.target.value.split('\\').pop();

      if (fileName) label.querySelector('span').innerHTML = fileName;
      else label.innerHTML = labelVal;
    });
  });
};

initBasementNav();
initDropDown();
initFileUpload();

// Direct upload stuff

window.addEventListener('direct-upload:initialize', event => {
  const { target, detail } = event;
  const { id, file } = detail;
  target.insertAdjacentHTML(
    'beforebegin',
    `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename">${file.name}</span>
    </div>
  `,
  );
});

window.addEventListener('direct-upload:start', event => {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.remove('direct-upload--pending');
});

window.addEventListener('direct-upload:progress', event => {
  const { id, progress } = event.detail;
  const progressElement = document.getElementById(
    `direct-upload-progress-${id}`,
  );
  progressElement.style.width = `${progress}%`;
});

window.addEventListener('direct-upload:error', event => {
  event.preventDefault();
  const { id, error } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add('direct-upload--error');
  element.setAttribute('title', error);
});

window.addEventListener('direct-upload:end', event => {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add('direct-upload--complete');
});
