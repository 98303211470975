import React from 'react';
import PropTypes from 'prop-types';
import uploadStates from '../uploadStates';

const className = 'progress-bar';

const stateToClassName = state => {
  const prefix = `${className}--`;

  switch (state) {
    case uploadStates.DIRECT_UPLOAD_IN_PROGRESS:
      return `${prefix}--pending`;

    case uploadStates.DIRECT_UPLOAD_ERROR:
      return `${prefix}--error`;

    case uploadStates.DIRECT_UPLOAD_COMPLETE:
      return `${prefix}--complete`;

    default:
      return '';
  }
};

const ProgressBar = props => {
  const { progress, state } = props;

  return (
    <div className={`${className} ${stateToClassName(state)}`}>
      <span style={{ width: `${progress * 100}%` }} />
    </div>
  );
};

ProgressBar.defaultProps = {
  progress: 0,
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  state: PropTypes.oneOf([
    uploadStates.DIRECT_UPLOAD_NOT_ASKED,
    uploadStates.DIRECT_UPLOAD_IN_PROGRESS,
    uploadStates.DIRECT_UPLOAD_FAILURE,
    uploadStates.DIRECT_UPLOAD_SUCCESS,
  ]).isRequired,
};

export default ProgressBar;
