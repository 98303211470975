import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const sizes = ['small', 'medium', 'large'];

const Spinner = ({ size }) => {
  const className = classnames({
    progress: true,
    small: size === 'small',
    large: size === 'large',
  });

  return (
    <div className={className}>
      <div>Loading…</div>
    </div>
  );
};

Spinner.defaultProps = {
  size: 'small',
};

Spinner.propTypes = {
  size: PropTypes.oneOf(sizes),
};

export default Spinner;
