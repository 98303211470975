import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import { localGet, localPostJSON } from '../localFetch';

const createOption = tag => ({ value: tag.id.toString(), label: tag.name });

const filterTags = (inputValue, options) => options;

const loadOptions = async inputValue => {
  const query = encodeURIComponent(inputValue);
  const data = await localGet(`/tags.json?q=${query}`);
  const options = data.map(createOption);
  return filterTags(inputValue, options);
};

export default class TagSelect extends Component {
  constructor(props) {
    super(props);
    this.handleCreate = this.handleCreate.bind(this);
    this.state = { isLoading: false };
  }

  handleChange(newValue) {
    this.setState(() => ({ selection: newValue }));
  }

  handleCreate(inputValue) {
    const { onCreate } = this.props;

    this.setState(() => ({ isLoading: true }));

    localPostJSON('/tags.json', { tag: { name: inputValue } }).then(tag => {
      const newOption = createOption(tag);
      this.setState(() => ({ isLoading: false }), () => onCreate(newOption));
    });
  }

  render() {
    const { selection, onChange } = this.props;
    const { isLoading } = this.state;

    return (
      <React.Fragment>
        <AsyncCreatableSelect
          className="tags"
          classNamePrefix="tags"
          isMulti
          onChange={onChange}
          onCreateOption={this.handleCreate}
          loadOptions={loadOptions}
          value={selection}
          isDisabled={isLoading}
          defaultOptions
        />
      </React.Fragment>
    );
  }
}

TagSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  selection: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
