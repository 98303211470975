import Promise from 'bluebird';

export default file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const data = reader.result;
      resolve(data);
    };

    reader.onerror = () => {
      reject(new Error('There was an error reading this file'));
    };

    reader.readAsDataURL(file);
  });
