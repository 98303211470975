// https://stackoverflow.com/a/14919494/456337

const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export default bytes => {
  const threshold = 1024;

  if (Math.abs(bytes) < threshold) {
    return `${bytes} B`;
  }

  let u = -1;
  let b = bytes;

  do {
    b /= threshold;
    u += 1;
  } while (Math.abs(b) >= threshold && u < units.length - 1);

  return `${b.toFixed(1)} ${units[u]}`;
};
