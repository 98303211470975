import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import Fade from './Fade';

// https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f

class CopyCode extends Component {
  constructor(props) {
    super(props);
    this.inputRef = createRef();
    this.handleClick = this.handleClick.bind(this);
    this.state = { hasCopied: false };
  }

  handleClick() {
    const el = this.inputRef.current;
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
    this.setState(
      () => ({ hasCopied: true }),
      () =>
        window.setTimeout(
          () => this.setState(() => ({ hasCopied: false })),
          3000,
        ),
    );
  }

  render() {
    const { code, successMessage } = this.props;
    const { hasCopied } = this.state;

    return (
      <>
        <input
          readOnly
          value={code}
          className="code"
          type="text"
          ref={this.inputRef}
        />

        <button
          className="button button--small button--secondary"
          onClick={this.handleClick}
          type="button"
        >
          Copy
        </button>

        <Fade inProp={hasCopied}>
          <small>{successMessage}</small>
        </Fade>
      </>
    );
  }
}

CopyCode.defaultProps = {
  successMessage: 'Copied!',
};

CopyCode.propTypes = {
  code: PropTypes.string.isRequired,
  successMessage: PropTypes.string,
};

export default CopyCode;
