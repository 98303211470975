import React, { Component } from 'react';
import PropTypes from 'prop-types';

class RolePicker extends Component {
  constructor(props) {
    super(props);
    this.onRoleSelect = this.onRoleSelect.bind(this);

    this.state = { selectedRoles: props.initialSelectedRoles };
  }

  onRoleSelect(event) {
    const { target } = event;
    const isChecked = target.checked;
    const value = parseInt(target.value, 10);

    this.setState(prevState => {
      let newRoles;
      if (isChecked) {
        newRoles = prevState.selectedRoles.concat([value]);
      } else {
        newRoles = prevState.selectedRoles.filter(id => id !== value);
      }

      return { selectedRoles: newRoles };
    });
  }

  render() {
    const { roles, isMe, defaultRoleMessage } = this.props;
    const { selectedRoles } = this.state;

    const isRoleCurrentlySelected = roleName =>
      roles
        .filter(({ id }) => selectedRoles.includes(id))
        .map(({ name }) => name)
        .includes(roleName);

    const visibleRoles = roles.filter(({ name }) => {
      if (isRoleCurrentlySelected('admin') && name !== 'admin') {
        return false;
      }

      if (isRoleCurrentlySelected('manage-art') && name === 'upload-art') {
        return false;
      }

      return true;
    });

    const renderRole = ({ id, name, label }) => {
      const inputId = `user_role_ids_${id}`;
      const isChecked = selectedRoles.includes(id);

      const labelTitle =
        isMe && name === 'admin'
          ? {
              title: 'You may not remove the Admin role from yourself.',
            }
          : {};

      const labelProps = {
        ...labelTitle,
      };

      return (
        <div key={id} className="input-checkbox">
          <input
            disabled={isMe && name === 'admin'}
            type="checkbox"
            value={id}
            checked={isChecked}
            name="user[role_ids][]"
            id={inputId}
            onChange={this.onRoleSelect}
          />
          <label htmlFor={inputId} {...labelProps}>
            {label}
          </label>
        </div>
      );
    };

    const descriptions = visibleRoles
      .filter(role => selectedRoles.includes(role.id))
      .map(role => <li key={role.id}>{role.description}</li>);

    const adminSelected = isRoleCurrentlySelected('admin');
    const roleNotificationClass = adminSelected ? 'success' : 'notice';

    return (
      <div className="role-picker form-field">
        <div className="role-picker__role-list stack stack--small">
          <input type="hidden" name="user[role_ids][]" value="" />
          {visibleRoles.map(renderRole)}
        </div>

        <div
          className={`role-picker__role-descriptions notification notification--${roleNotificationClass}`}
        >
          <p>The user may:</p>
          <ul>
            {!adminSelected && <li>{defaultRoleMessage}</li>}
            {descriptions}
          </ul>
        </div>
      </div>
    );
  }
}

RolePicker.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isMe: PropTypes.bool.isRequired,
  initialSelectedRoles: PropTypes.arrayOf(PropTypes.number).isRequired,
  defaultRoleMessage: PropTypes.string.isRequired,
};

export default RolePicker;
