import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TagSelect from './TagSelect';

const createOption = tag => ({ value: tag.id.toString(), label: tag.name });

export default class TagSelectFormField extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    const selection = props.defaultOptions.map(createOption);
    this.state = { selection };
  }

  handleChange(newValue) {
    this.setState(() => ({ selection: newValue }));
  }

  handleCreate(newValue) {
    this.setState(({ selection }) => ({ selection: [...selection, newValue] }));
  }

  render() {
    const { selection } = this.state;
    const { name } = this.props;

    const hiddenInputsForValues = selection.map(({ value }) => (
      <input key={value} type="hidden" name={name} value={value} />
    ));

    const hiddenInputs =
      hiddenInputsForValues.length > 0 ? (
        hiddenInputsForValues
      ) : (
        <input type="hidden" name={name} value="" />
      );

    return (
      <React.Fragment>
        <TagSelect
          selection={selection}
          onChange={this.handleChange}
          onCreate={this.handleCreate}
        />
        {hiddenInputs}
      </React.Fragment>
    );
  }
}

TagSelectFormField.defaultProps = {
  name: 'image[tag_ids][]',
  defaultOptions: [],
};

TagSelectFormField.propTypes = {
  name: PropTypes.string,
  defaultOptions: PropTypes.arrayOf(PropTypes.object),
};
