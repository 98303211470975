import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

const Fade = ({ inProp, duration, children }) => {
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
  };

  return (
    <Transition in={inProp} timeout={duration}>
      {state => (
        <div
          className="popover"
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

Fade.defaultProps = {
  duration: 300,
};

Fade.propTypes = {
  inProp: PropTypes.bool.isRequired,
  duration: PropTypes.number,
  children: PropTypes.element.isRequired,
};

export default Fade;
